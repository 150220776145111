.droppable {
  // background-color: rgb(131, 221, 243);
  display: inline-grid;
  width: 10rem;
  height: 1.5rem;
  margin: -1rem 1rem auto;
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.3);
  border-radius: 0.25rem;
  // transform: translateY(0.25rem);

  .draggable {
    height: unset;
    padding: unset;
    margin: unset;
    background-color: #1976d2;
    color: white;
    border-radius: 0.25rem;
  }
}

.questionContainer {
  // display: flex;
  // flex-wrap: wrap;

  // & * + * {
  //   margin-left: 1rem;
  // }
  line-height: 2.5;
}

.questionText {
  display: inline;
}

.choices {
  margin-top: 2rem;
  display: inline-flex;
  flex-wrap: wrap;
  // gap: 1rem;
  margin: 1rem 0 0 -1rem;

  > .draggable {
    margin: 1rem 0 0 1rem;
  }
}

.draggable {
  // margin: 0.5rem;
  height: 3rem;
  padding: 0.5rem;
  cursor: grab;
  border: 0;
  box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.3);

  &[aria-pressed='true'] {
    cursor: grabbing;
  }
}
