.container {
  // max-width: 80ch;
  margin: 0 auto;
}

.question {
  margin: 1rem;
}

.selected {
  font-weight: bold;
}
